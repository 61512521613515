<template>
  <div class="container" id="detail" v-title :data-title="$t('i18n.onlineMonitoring')"
    :class="{ mobileCss: !isMobile }">
    <div v-if="!isMobile" class="text">
      <label>健康值：</label>
      <span>{{ healthTotal }}</span>
      <label>{{ $t("i18n.elevatorNo") }}：</label>
      <span>{{ elevator.number }}</span>
      <label>{{ $t("i18n.projectAlias") }}：</label>
      <span>{{ elevator.alias }}</span>
    </div>
    <div v-else>
      <h3 class="text-center">
        {{ $t("i18n.elevatorNo") }}：{{ elevator.number }}
      </h3>
      <p class="text-center">
        {{ $t("i18n.projectAlias") }}：{{ elevator.alias }}
      </p>
    </div>
    <el-button v-if="!isMobile" id="viewEleInfo" @click="enterDetail" type="primary">
      {{ $t("i18n.viewEleInfo") }}
    </el-button>
    <div class="main" v-if="elevator.elevatorVariety">
      <div class="left fl" style="height: 680px;">
        <div class="form-control">
          <label>{{ $t("i18n.controllerType") }}</label>
          <span>{{ elevator.controllerType }}</span>
        </div>
        <div class="form-control">
          <label>{{ $t("i18n.systemRunningTime") }}</label>
          <span v-if="elevator.sysRuntime">
            {{ elevator.sysRuntime }}{{ $t("i18n.hours") }}
          </span>
        </div>
        <div class="form-control" v-if="elevator.elevatorVariety != 30">
          <label>{{ $t("i18n.runningTimes") }}</label>
          <span v-if="elevator.runCount">
            {{ elevator.runCount }}{{ $t("i18n.times") }}
          </span>
        </div>
        <div v-show="elevator.faultStatus == '20'" class="form-control">
          <label>{{ $t("i18n.faultCode") }}</label>
          <span style="color: red">{{ elevator.faultCode }}</span>
        </div>
        <div v-show="elevator.faultStatus == '20'" class="form-control">
          <label>{{ $t("i18n.timeOfFault") }}</label>
          <span>{{ elevator.faultTime }}</span>
        </div>
        <div v-if="!isMobile && elevator.elevatorVariety != 30 " class="form-control ">
          <label style="width: 50px">{{ $t("i18n.speed") }}</label>
          <span>{{ elevator.runningSpeed }}</span>
          <label style="width: 50px">{{ $t("i18n.personCount") }}</label>
          <span>{{ elevator.runningPersonCount }}</span>

        </div>
        <!-- <div v-if="!isMobile && elevator.elevatorVariety != 30 " class="form-control  ">
          <label style="width: 50px">{{ $t("i18n.press") }}</label>
          <span>{{ elevator.runningPress }}</span>

        </div> -->
        <!-- <div v-if="!isMobile && elevator.elevatorVariety != 30" class="form-control  ">
          <label style="width: 50px">{{ $t("i18n.personCount") }}</label>
          <span>{{ elevator.runningPersonCount }}</span>

        </div> -->
        <div v-if="!isMobile && elevator.elevatorVariety != 30 && elevator.moduleType != '60'"
          class="form-control floor_span">
          <label style="width: 50px">{{ $t("i18n.upCall") }}</label>
          <el-scrollbar>
            <div class="flex-content">
              <span v-for="item in elevator.upCall" :key="item">
                {{ item }}
              </span>
            </div>
          </el-scrollbar>
        </div>
        <div v-if="!isMobile && elevator.elevatorVariety != 30 && elevator.moduleType != '60'"
          class="form-control floor_span">
          <label style="width: 50px">{{ $t("i18n.downCall") }}</label>
          <el-scrollbar>
            <div class="flex-content">
              <span v-for="item in elevator.downCall" :key="item">
                {{ item }}
              </span>
            </div>
          </el-scrollbar>
        </div>
        <div v-if="!isMobile && elevator.elevatorVariety != 30 && elevator.moduleType != '60'"
          class="form-control floor_span">
          <label style="width: 50px">{{ $t("i18n.callInCar") }}</label>
          <el-scrollbar>
            <div class="flex-content">
              <span v-for="item in elevator.callInCar" :key="item">
                {{ item }}
              </span>
            </div>
          </el-scrollbar>
        </div>
        <div id="status" v-if="!isMobile && elevator.elevatorVariety == 30">
          <label v-if="elevator.flow == '010' || elevator.flow == '011'">{{ $t("上行运行中") }}</label>
          <label v-if="elevator.flow == '101' || elevator.flow == '100'">{{ $t("下行运行中") }}</label>
        </div>
        <div v-if="!isMobile" class="absolutes">
          <div class="item" v-for="item in statusImg" :key="item">
            <img v-if="!item.isFault" :src="require('../../../public/images/' + item.icon)" />
            <img v-else :src="require('../../../public/images/' + item.icon1)" />
            <div>
              <span :style="{ color: item.color }">
                {{ $t("i18n." + item.name) }}
              </span>
            </div>
          </div>
        </div>
        <div v-if="!isMobile" class="buttons">
          <template v-for="item in buttons" :key="item">
            <button v-if="menusJson.indexOf(item.url) > -1 || item.isNotPage" type="button" @click="enterPage(item)"
              :style="{ background: item.bg }">
              {{ $t("i18n." + item.name) }}
            </button>
          </template>
        </div>
      </div>
      <div class="middle" style="height: 680px;">
        <div v-if="elevator.elevatorVariety != 30" class="floorImg">
          {{ elevator.floor }}
        </div>
        <img v-if="elevator.elevatorVariety == 30" class="automatic" src="../../../public/images/automatic.png" />
        <img v-else-if="!elevator.runImg" class="elevatorImg" src="../../../public/images/elevator_big_close.png" />
        <img v-else class="elevatorImg" :src="require('../../../public/images/' + elevator.runImg)" />
        <div class="absolute">
          <img v-if="elevator.signalIntensity" class="signalImg" :src="require('../../../public/images/' +
    elevator.signalIntensity +
    '.png')
    " />
          <div class="up-area">
            <img v-if="elevator.flow == '010' || elevator.flow == '011'" class="direction-up"
              src="../../assets/flowupgreen.png" />
          </div>
          <img v-if="elevator.maintenanceStatus == 20" src="../../assets/recondition.png" />
          <img v-else-if="elevator.faultStatus == 20 && elevator.isHandled == 20"
            src="../../assets/faultHandling2.png" />
          <img v-else-if="elevator.faultStatus == 20 && elevator.isHandled != 20" src="../../assets/malfunction.png" />
          <div v-else-if="elevator.floor &&
    elevator.floor != '通讯异常' &&
    elevator.elevatorVariety != 30
    " class="floor-ind">
            {{ elevator.floor }}
          </div>
          <div class="down-area">
            <img v-if="elevator.flow == '101' || elevator.flow == '100'" class="direction-down"
              src="../../assets/flowdowngreen.png" />
          </div>
        </div>
      </div>
      <div class="right fr" style="height: 680px;">
        <div class="form-control">
          <label>{{ $t("i18n.intelligenceCode") }}</label>
          <span>{{ elevator.intelHardwareNumber }}</span>
        </div>
        <div class="form-control">
          <label>{{ $t("i18n.elevatorModel") }}</label>
          <span>{{ elevator.elevatorModel }}</span>
        </div>
        <div class="form-control">
          <label>{{ $t("i18n.elevatorType") }}</label>
          <span>{{ setTypeFilter(elevator.elevatorVariety) }}</span>
        </div>
        <div class="form-control">
          <label>{{ $t("i18n.manufactureDate") }}</label>
          <span>{{ elevator.productionTime }}</span>
        </div>
        <div class="form-control">
          <label>{{ $t("i18n.useOccasion") }}</label>
          <span>{{ setLineagesFilter(elevator.usageType) }}</span>
        </div>
        <div class="form-control">
          <label>{{ $t("i18n.layerStationDoor") }}</label>
          <span>{{ elevator.station }}</span>
        </div>
        <div class="form-control">
          <label>{{ $t("i18n.manufacturingCompany") }}</label>
          <span>{{ elevator.manufacturerName }}</span>
        </div>
        <div class="form-control">
          <label>{{ $t("i18n.installationCompany") }}</label>
          <span>{{ elevator.installCompanyName }}</span>
        </div>
        <div class="form-control">
          <label>{{ $t("i18n.address") }}</label>
          <span>{{ elevator.address }}</span>
        </div>
        <div class="form-control">
          <label>{{ $t("i18n.buildingUsingParty") }}</label>
          <span>{{ elevator.userCompanyName }}</span>
        </div>
        <div class="form-control">
          <label>{{ $t("i18n.propertyCompany") }}</label>
          <span>{{ elevator.ownerCompanyName }}</span>
        </div>
        <div class="form-control">
          <label>{{ $t("i18n.maintenanceCompany") }}</label>
          <span>{{ elevator.maintainerName }}</span>
        </div>
        <div class="form-control">
          <label>{{ $t("i18n.hourLineOnDuty") }}</label>
          <span>{{ elevator.dutyPhone }}</span>
        </div>
        <div class="form-control">
          <label>{{ $t("i18n.maintenanceContractPerson") }}</label>
          <span>{{ elevator.maintenanceName }}</span>
        </div>
        <div class="form-control">
          <label>{{ $t("i18n.propertyContract") }}</label>
          <span>{{ elevator.ownerManager }}</span>
        </div>
        <div class="form-control">
          <label>{{ $t("i18n.lastAnnualInspectionDate") }}</label>
          <span>{{ elevator.lastCheckDate }}</span>
        </div>
        <div class="form-control">
          <label>{{ $t("i18n.lastMaintenanceDate") }}</label>
          <span>{{ elevator.lastUpkeepTime }}</span>
        </div>
        <div v-if="isMobile" class="buttons">
          <template v-for="item in buttons" :key="item">
            <button v-if="menusJson.indexOf(item.url) > -1 || item.isNotPage" type="button" @click="enterPage(item)"
              :style="{ background: item.bg }">
              {{ $t("i18n." + item.name) }}
            </button>
          </template>
        </div>
      </div>
    </div>
    <el-dialog :title="$t('i18n.statusReport')" v-model="dialogVisible" width="40%" :before-close="handleClose"
      destroy-on-close>
      <div id="healthCharts" style="height: 220px;"></div>
      <el-form :model="statusReportData" :label-width="$i18n.locale == 'zh' ? '80px' : '200px'">
        <el-form-item :label="$t('i18n.statusReport')">
          <span style="color: green" v-if="statusReportData.result == '正常运行'">
            &nbsp;&nbsp;{{ statusReportData.result }}
          </span>
          <span style="color: red" v-else>
            &nbsp;&nbsp;{{ statusReportData.result }}
          </span>
        </el-form-item>
        <el-form-item :label="$t('i18n.maintainstatus')" v-if="statusReportData.nextPlanDays">
          <span>&nbsp;&nbsp;{{ statusReportData.nextPlanDays }}</span>
        </el-form-item>
        <el-form-item :label="$t('i18n.runningState')" v-if="statusReportData.normalRunDays">
          <span>&nbsp;&nbsp;{{ statusReportData.normalRunDays }}</span>
        </el-form-item>
        <el-form-item :label="$t('i18n.aiStatus')" v-if="statusReportData.nextAnnualDays">
          <span>&nbsp;&nbsp;{{ statusReportData.nextAnnualDays }}</span>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="dialogVisible = false">
          {{ $t("i18n.cancel") }}
        </el-button>
      </template>
    </el-dialog>

    <el-dialog v-if="dialogVisible2" :title="直播" v-model="dialogVisible2" width="840px" height="560px"
      :before-close="handleClose" destroy-on-close>
      <div class="root">
        <VideoPlay :othersId="elevator.othersId" :accessToken="accessToken" />
      </div>
      <template #footer>
        <el-button @click="dialogVisible2 = false">
          {{ $t("i18n.cancel") }}
        </el-button>
      </template>
    </el-dialog>
  </div>
  <jl-line v-if="!isMobile" :id="$route.query.id"></jl-line>
  <div style="height: 100px; margin: 0 auto;"></div>
</template>
<script>
import moment from 'moment'
import jlLine from "../../components/timeLine";
import socket from "../../../public/js/socket";
import VideoPlay from "./VideoPlay.vue"
// import VuePlayer from "./VuePlayer"
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { reactive, toRefs, getCurrentInstance } from "vue";
export default {
  name: "Detail",
  components: {
    jlLine,
    VideoPlay
  },

  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      id: "",
      sid: "",
      videoUrl: "ws://192.168.200.13:80/rtp/34020000001110000001_34020000001310000001.live.flv",
      isFault: false, // 是否故障
      usageTypePond: "",
      statusImg: [
        {
          id: 1,
          icon: "Recondition-Off.png",
          icon1: "Recondition-On.png",
          name: "recondition",
          color: "#1DACA0",
          isFault: false,
        },
        {
          id: 2,
          icon: "Malfuncion-Off.png",
          icon1: "Malfuncion-On.png",
          name: "malfunction",
          color: "#ED3309",
          isFault: false,
        },
        {
          id: 3,
          icon: "Overload-Off.png",
          icon1: "Overload-On.png",
          name: "overload",
          color: "#F59B4B",
          isFault: false,
        },
      ],
      buttons: [
        {
          name: "statusReport",
          bg: "linear-gradient(-30deg,#1962E6,#278CF3)",
          url: "#",
          isNotPage: true,
        },
        {
          name: "elevatorEvent",
          bg: "linear-gradient(-30deg,#1962E6,#278CF3)",
          url: "/realTime/eleHistoryFault",
          isNotPage: true,
        },
        {
          name: "videoMonitor",
          bg: "#A763EF",
          url: "",
          isNotPage: true,
        },
        {
          name: "gridRescue",
          bg: "#1DACA0",
          url: "/gridRescue/gridRescue",
        },
        {
          name: "maintenancePlan",
          bg: "#FEA276",
          url: "/maintenance/maintenancePlan",
        },
      ],
      menusJson: [],
      dialogVisible: false,
      dialogVisible2: false,
      statusReportData: "",
      isMobile: route.query.mobile,
      elevator: {},
      lineages: "",
      healthCharts: '',
      healthTotal: '',
      accessToken: ''
    });

    const init = () => {
      emit("public_header", !route.query.mobile);
      state.id = route.query.id;
      healthChartsDetail();
      getQuery();
      getDetails();
      parseJson(proxy.$defined.MENU);
      getEleStatus();
      getLineages();
      getUsageTypeData();
    };

    const getUsageTypeData = async () => {
      let { data } = await proxy.$api.common.getLineageByCode("usage_type");
      state.usageTypePond = data.lineages;
    };

    // const play = () =>{
    //   console.log("hahaha");
    //   // var href = $playHref.value;
    //   // console.log(href);
    //   var href = "ws://192.168.200.13:80/rtp/34020000001110000001_34020000001310000001.live.flv"
    //     if (href) {
    //         jessibuca.play(href);
    //         $player.style.display = 'none';
    //         $pause.style.display = 'inline-block';
    //         $destroy.style.display = 'inline-block';
    //     }
    // }

    const getDetails = async () => {
      let { data } = await proxy.$api.system.getElevatorDetail(state.id);
      state.elevator = data;
      let item = await proxy.$api.common.getAccToken();
      state.accessToken = item.data.data.accessToken
      socket.set(state.elevator, (ele) => {
        // state.elevator = JSON.parse(JSON.stringify(ele[0]));
        // console.log("上下行",state.elevator)
        // if(ele[0].intelHardwareNumber ==state.elevator.intelHardwareNumber){
        state.statusImg[0].isFault = state.elevator.check == "1";
        state.statusImg[1].isFault = state.elevator.fault == "1";
        state.statusImg[2].isFault = state.elevator.overload == 3;
        // }

      });
    };

    const getEleStatus = async () => {
      let { data } = await proxy.$api.system.getEleStatus(state.id);
      state.isFault = true;
      state.statusReportData = data;
    };

    const parseJson = (jsonObj) => {
      for (var key in jsonObj) {
        var element = jsonObj[key];
        if (element.children.length > 0) {
          parseJson(element.children);
        } else {
          state.menusJson.push(element.href);
        }
      }
    };

    const getLineages = async () => {
      let { data } = await proxy.$api.common.getLineageByCode("elevator_variety");
      state.lineages = data.lineages;
    };

    const setLineagesFilter = (val) => {
      let arr = state.usageTypePond.filter((obj) => {
        return obj.value == val;
      });
      return arr.length > 0 ? t("i18n." + arr[0].code) : t("i18n.none");
    };

    const setTypeFilter = (val) => {
      let arr = state.lineages.filter((obj) => {
        return obj.value == val;
      });
      return arr.length > 0 ? t("i18n." + arr[0].code) : t("i18n.none");
    };

    const getQuery = () => {
      if (proxy.$defined.btnPermission("派发工单")) {
        state.buttons.push({
          name: "initiateMaintenance",
          bg: "#FFB574",
          url: "/maintenance/workBillBoard",
        });
      }
      if (state.isMobile) {
        state.buttons = [];
      }
      if (proxy.$defined.btnPermission("端子状态") || state.isMobile) {
        state.buttons.push({
          name: "terminalState",
          bg: "#63BFFF",
          url: "/realTime/terminalState",
          isNotPage: true,
        });
      }
      if (proxy.$defined.btnPermission("电梯参数") || state.isMobile) {
        state.buttons.push({
          name: "liftParameters",
          bg: "#FFAD9B",
          url: "/realTime/liftParams",
          isNotPage: true,
        });
      }
      if (proxy.$defined.btnPermission("视频监控") || state.isMobile) {
        state.buttons.push({
          name: "videoMonitor",
          bg: "#A763EF",
          url: "",
          isNotPage: true,
        });
      }
    };

    const enterPage = (item) => {
      var path = item.url;
      if (path == "") {
        var arr = [
          "GKT31609",
          "GKT28009",
          "17G01286",
          "17G01285",
          "17G01284",
          "17G01283",
          "15G00001",
          "14G12423",
          "11G58070",
        ]; // 9台有3G的电梯
        if (arr.indexOf(state.elevator.number) > -1) {
          path =
            "https://hcdt.dataserver.cn/1.1/monitor/hangzhou/elevatorRealTimeCall.jsp?registerCode=" +
            state.elevator.number;
          window.open(path, "_blank");
        } else if (state.elevator.othersId != null) {
          state.dialogVisible2 = true;
          state.videoUrl = state.elevator.othersId;
          return false;
        }
        else {
          proxy.$defined.tip(t("i18n.noneFunction"));
        }
        return false;
      } else if (path == "#") {
        state.dialogVisible = true;
        healthChartsDetail();
        return false;
      }
      var query = { enumber: state.elevator.number, id: state.elevator.id };
      if (item.name == "initiateMaintenance") {
        query.billModel = 50;
      }
      if (state.isMobile) {
        query.mobile = 1;
      }
      let routeData = router.resolve({
        path: path,
        query: query,
      });
      window.open(routeData.href, "_blank");
    };
    const healthChartsDetail = async () => {
      let healthChartList = []
      let date = moment().format('YYYY-MM-DD')
      let { data } = await proxy.$api.monitor.getHealth(state.id);
      healthChartList[1] = data.faultCount >= 20 ? 0 : 20 - data.faultCount
      let diffBirth = moment(date).diff(date.birthday, "year")
      let diffLatMaintenance = moment(date).diff(date.latMaintenance, "day")
      healthChartList[3] = data.currentStatus.faultStatus == 10 ? 20 : 10
      if (data.emergencyCount == 0) {
        healthChartList[4] = 20
      } else if (1 <= data.emergencyCount <= 5) {
        healthChartList[4] = 10
      } else {
        healthChartList[4] = 0
      }
      if (0 <= diffBirth <= 5) {
        healthChartList[0] = 20
      } else if (6 <= diffBirth <= 10) {
        healthChartList[0] = 15
      } else if (11 <= diffBirth <= 15) {
        healthChartList[0] = 10
      } else {
        healthChartList[0] = 0
      }
      if (0 <= diffLatMaintenance <= 5) {
        healthChartList[2] = 20
      } else if (6 <= diffLatMaintenance <= 10) {
        healthChartList[2] = 15
      } else if (11 <= diffLatMaintenance <= 15) {
        healthChartList[2] = 10
      } else {
        healthChartList[2] = 0
      }
      state.healthTotal = healthChartList.reduce((accumulator, currentValue) => accumulator + currentValue);
      state.healthCharts = echarts.init(
        document.getElementById("healthCharts")
      );
      setHealthCharts(healthChartList)
    };
    const setHealthCharts = (healthChartList) => {
      state.healthCharts.showLoading({
        text: '数据加载中...',
        color: '#c23531',
        textColor: '#ffffc2',
        maskColor: 'rgba(255, 255, 255, 0)',
        zlevel: 0
      });
      var option = {
        radar: {
          // shape: 'circle',
          indicator: [
            { name: '年限', max: 20 },
            { name: '故障次数', max: 20 },
            { name: '保养', max: 20 },
            { name: '当前状态', max: 20 },
            { name: '维修', max: 20 },
          ]
        },
        series: [
          {
            type: 'radar',
            data: [
              {
                value: healthChartList,
              },
            ]
          }
        ]
      };
      state.healthCharts.hideLoading()
      state.healthCharts.setOption(option);
    }
    const enterDetail = () => {
      let routeData = router.resolve({
        path: "/elevator/addEditElevator",
        query: { flag: 1, id: state.id },
      });
      window.open(routeData.href, "_blank");
    };

    init();

    return {
      ...toRefs(state),
      setTypeFilter,
      enterDetail,
      enterPage,
      setLineagesFilter,
    };
  },
};
</script>

<style lang="scss">
#detail.container {
  min-height: 56%;
  padding-bottom: 0;

  #viewEleInfo {
    position: absolute;
    top: 25px;
    right: 30px;
  }

  .text {
    text-align: center;
    font-size: $font + 4;

    label {
      margin-left: 20px;
    }

    span {
      color: #ccc;
    }
  }

  .main {
    margin-top: 13px;
    text-align: center;

    .left,
    .right {
      width: 30%;
      background: $gray1_color;

      .form-control {
        font-size: $font + 1;
        margin-bottom: 15px;
        text-align: left;
        display: flex;

        label {
          color: $label_color;
          flex-shrink: 0;
        }

        span {
          width: 100%;
          color: $blue1_color;
        }
      }

      .absolutes {
        .item {
          margin-bottom: 20px;
          display: inline-block;
          width: 33%;
          text-align: center;

          span {
            width: 50px;
            display: inline-block;
            text-align: center;
          }
        }
      }

      .buttons {
        width: 3 * $width;
        margin: 0 auto;
        text-align: left;

        button {
          width: $width + 38;
          height: 36px;
          border: none;
          color: $white;
          font-size: $font + 2;
          border-radius: 18px;
          margin: 5px;
          vertical-align: middle;
        }
      }
    }

    .middle {
      width: 36%;
      border: 1px solid $border_color;
      display: inline-block;
      position: relative;

      .floorImg {
        width: 124px;
        height: 62px;
        margin: 0 auto;
        color: $red_color;
        font-size: $font + 4;
        line-height: 47px;
        background: url("../../assets/bigfloor.png") no-repeat 4px center;
      }

      .elevatorImg {
        margin-top: -20px;
        width: 360px;
        margin-bottom: 10px;
      }

      .automatic {
        margin: 80px 0;
        width: 90%;
      }

      button {
        display: block;
        margin: 15px auto;
        width: $width + 88;
        height: 40px;
        font-size: $font + 2;
        background: #00a0e9;
        color: $white;
        border: none;
        border-radius: 20px;
      }

      .absolute {
        position: absolute;
        top: 0;
        right: 0;
        width: 60px;
        height: 100%;
        padding: 9px 13px 0 0;

        .signalImg {
          float: right;
        }

        .up-area {
          margin-top: 60px;
          line-height: 33;
        }

        .up-area,
        .down-area {
          width: 45px;
          height: $width * 2 + 45;
        }

        .direction-up {
          animation: donghuadown 1.5s infinite;
        }

        .direction-down {
          animation: donghuaup 1.5s infinite;
        }

        .floor-ind {
          width: 33px;
          height: 33px;
          line-height: 33px;
          color: $white;
          background: $green_color;
        }
      }
    }

    .left {
      padding: 25px 24px;

      label {
        width: 110px;
      }

      .floor_span {
        height: 25px;

        .flex-content {
          display: flex;
        }

        span {
          flex-shrink: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 25px;
          height: 25px;
          line-height: 25px;
          text-align: center;
          margin: 0 10px 10px 0;
          display: inline-block;
          border-radius: 50%;
          background: #2687f2;
          color: #fff;
        }
      }

      #status {
        padding: 75px 0;

        label {
          background: #1aab9e;
          color: #fff;
          padding: 20px 40px;
        }
      }
    }

    .right {
      padding: 17px 15px;

      .form-control {
        margin-bottom: 16px;

        &:last-child {
          margin: 0;
        }
      }

      span {
        text-align: right;
        word-break: break-all;
        padding-left: 10px;
      }
    }
  }
}

.root {
  display: flex;
  place-content: center;
  margin-top: 3rem;
  width: 800px;
  height: 500px;
}

.container-shell {
  backdrop-filter: blur(5px);
  background: hsla(0, 0%, 50%, 0.5);
  padding: 30px 4px 10px 4px;
  /* border: 2px solid black; */
  width: auto;
  position: relative;
  border-radius: 5px;
  box-shadow: 0 10px 20px;
}

.container-shell:before {
  content: "钜立智能";
  position: absolute;
  color: darkgray;
  top: 4px;
  left: 10px;
  text-shadow: 1px 1px black;
}

#container {
  background: rgba(13, 14, 27, 0.7);
  width: 640px;
  height: 398px;
}

.input {
  display: flex;
  margin-top: 10px;
  color: white;
  place-content: stretch;
}

.input2 {
  bottom: 0px;
}

.input input {
  flex: auto;
}

.err {
  position: absolute;
  top: 40px;
  left: 10px;
  color: red;
}

.option {
  position: absolute;
  top: 4px;
  right: 10px;
  display: flex;
  place-content: center;
  font-size: 12px;
}

.option span {
  color: white;
}

.page {
  background: url('../../../public/js/video/bg.jpg');
  background-repeat: no-repeat;
  background-position: top;
}

@media (max-width: 720px) {
  #container {
    width: 90vw;
    height: 52.7vw;
  }
}

@media screen and (max-width: 1024px) {
  #detail.container .main {
    .left {
      .buttons {
        width: 240px;

        button {
          width: 110px;
          font-size: $font;
        }
      }
    }

    .middle {
      .elevatorImg {
        width: 340px;
      }
    }

    .left,
    .middle,
    .right {
      height: 680px !important;
      overflow-y: auto;
    }
  }

  #timeLine.container {
    margin: 5px;
    padding: 10px 5px;
    overflow-x: auto;
  }
}

@media screen and (max-width: 768px) {
  #detail.container {
    margin: 0;
    padding: 0;

    h3 {
      padding-top: 10px;
      font-size: 22px;
      font-weight: normal;
    }

    p {
      font-size: 16px;
      margin-top: 23px;
    }

    .main {
      .middle {
        display: none;
      }

      .left,
      .right {
        background: transparent;
        padding: 15px 0 0;
        width: 100%;

        .form-control {
          padding: 10px 15px;
          margin: 0;

          label {
            width: 125px;
          }

          span {
            text-align: left;
            padding-left: 10px;
            color: #a5a5a8;
          }
        }

        .buttons {
          padding: 10px 0;
        }
      }

      .left {
        .form-control:nth-child(2n) {
          background: #f6f6f6;
        }
      }

      .right {
        padding-top: 0;

        .form-control:nth-child(2n + 1) {
          background: #f6f6f6;
        }
      }
    }
  }

  #detail.container.mobileCss {
    margin: -20px 5px 0;

    .text {
      padding-top: 10px;
      font-size: 14px;
      text-align: left;

      label {
        margin-left: 5px;
      }
    }

    #viewEleInfo {
      position: relative;
      top: 0;
      right: 0;
      display: block;
      margin: 10px auto;
    }

    .left {
      padding-top: 0;
    }

    .absolutes {
      margin-top: 15px;
    }
  }

  #timeLine.container {
    margin: 5px;
    padding: 10px 5px;
    overflow-x: auto;
  }
}
</style>
